/**
 * Why Cache IP Information from ipapi.co?
 *
 * Caching IP information helps in several ways:
 *
 * 1. **Request Limits**:
 *    Reduces the frequency of API calls, helping to stay within ipapi.co's request limits and avoid rate limiting errors.
 *
 * 2. **Reliability**:
 *    Ensures the app continues to function smoothly if ipapi.co is temporarily unavailable, using cached data as a fallback.
 *
 * 3. **Consistency**:
 *    Provides consistent IP data over a short period, reducing unnecessary requests for frequently unchanged information.
 *
 * In summary, caching improves reliability, performance, and user experience by minimizing dependence on real-time requests.
 */

import { IpInfo } from "../api/api.types";

const CACHE_EXPIRATION_MS = 24 * 60 * 60 * 1000; // 24 hours

const CACHE_KEY = "ipInfo";

// Define a minimal type for cached IP information
export type MinimalIpInfo = Pick<IpInfo, "ip" | "city" | "country_name">;

type CachedMinimalIpInfo = {
  ipInfo: MinimalIpInfo;
  timestamp: number;
};

export const getCachedIpInfo = (): MinimalIpInfo | null => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (!cachedData) return null;

  const { ipInfo, timestamp }: CachedMinimalIpInfo = JSON.parse(cachedData);
  return Date.now() - timestamp < CACHE_EXPIRATION_MS ? ipInfo : null;
};

export const setCachedIpInfo = (ipInfo: IpInfo): void => {
  const timestamp = Date.now();
  const minimalIpInfo: MinimalIpInfo = {
    ip: ipInfo.ip,
    city: ipInfo.city,
    country_name: ipInfo.country_name,
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify({ ipInfo: minimalIpInfo, timestamp }));
};
